<template>
    <div class="footer" align="right">

        <!-- style="max-width: 500px; border: 1px SOLID #000; background-color: #cc6600; color: #fff;" //-->
        <div align="center"  class="shadow-24" style="padding-left: 10px; padding-right: 10px;">
            <table>
                <tbody>
                    <tr>

                        <td align="left">{{unita_operativa}}</td>
                        <td rowspan="2">
                            <q-avatar color="white" text-color="white" size="64px">
                                <img :src="avatar" width="52px">
                            </q-avatar>
                        </td>
                    </tr>
                    <tr>
                        <td align="left">{{operatore}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "PiattaformaRami_Footer",
    data() {
        return {
        }
    },
    components: {
    },
    computed: {
        ...mapGetters([
            "jwtPayload"
        ]),
        operatore() { return this.jwtPayload.operatore},
        unita_operativa() { return this.jwtPayload.nome_unita_operativa},
        sesso() { return this.jwtPayload.sesso},
        avatar() {
            var dt = new Date();
            let mese = dt.getMonth();

            if (mese !== 11) {
                switch(this.sesso) {
                    case "MASCHIO": return require("@/images/male3.png");
                    case "FEMMINA": return require("@/images/female.png");
                    default: return require("@/images/altro1.png");
                }
            }

            return require("@/images/natale.png");
        },
    }
}
</script>

<style>
    .footer {
        position: fixed;
        bottom: 10px;
        font-weight: bold;
        right: 10px;
        /*min-width: 500px;*/
        /*width: 500px;*/
        border: 1px SOLID #000;
        color: #fff;
        background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(125,185,232,1) 100%);
        background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(125,185,232,1) 100%);
        background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(125,185,232,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=1 );
    }

</style>
